<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "database"
}
</script>

<style scoped>

</style>
